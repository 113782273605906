import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import ErrorBoundary from './ErrorBoundary'

import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('main-chatgds-app'))
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
)

reportWebVitals()
