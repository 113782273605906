import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  Column,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
} from 'design-system'

import { notify } from 'helpers'

import { sendMessageTicket, showTemplatesList } from 'services'
import PreviewTemplate from 'components/specific/tickets/PreviewTemplate'

const ModalSendConversationTemplate = ({ open, onClose, ticket, callBack }) => {
  const [loading, setLoading] = useState(false)
  const [templatesList, setTemplatesList] = useState([])

  useEffect(() => {
    handleGetTemplatesList(ticket?.connection?.id)
  }, [ticket])

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm()

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      values.message_type = 'template'
      values.template = values.template.id
      values.template_params = values.params.filter((i) => i.param).map((i) => i.text)

      await sendMessageTicket(ticket?.id, values)

      callBack && callBack(true)
      onClose()
    } catch {
      notify.error('Não foi possível enviar template na conversa.')
    } finally {
      setLoading(false)
    }
  }

  const handleGetTemplatesList = async (connection) => {
    try {
      setLoading(true)
      const { data } = await showTemplatesList({
        connection,
        page_size: 100,
        status: 'APPROVED',
      })

      setTemplatesList(data.results)
    } catch {
      notify.error('Não foi possível resgatar listagem de templates.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal open={open}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          Enviar template
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Column>
            <PreviewTemplate
              name='template'
              control={control}
              register={register}
              watch={watch}
              errors={errors}
              setValue={setValue}
              templatesList={templatesList}
            />
          </Column>
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Enviar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalSendConversationTemplate
