import { Checkbox, Column, Input, Row, Typography } from 'design-system'
import { regexPatterns } from 'utils'

const CustomField = ({ register, errors, extrafieldsList, loading }) => {
  const renderExtraFieldDict = {
    text: (props) => <Input {...props} />,
    boolean: (props) => <Checkbox {...props} />,
    integer: (props) => <Input {...props} />,
    decimal: (props) => <Input {...props} />,
  }

  return (
    <>
      <Typography variant='caption' fontWeight='600'>
        Campos personalizados
      </Typography>
      <Row gap='8px' p='0 16px 0 0' maxHeight='250px' overflow='auto' flexWrap='wrap'>
        {extrafieldsList?.map(({ id, field_type, name }, idx) => (
          <Column key={`${id}_${idx}`} width='100%' mt='16px'>
            <input hidden value={id} {...register(`extra_fields.${idx}.extra_field.id`)} />
            {renderExtraFieldDict[field_type]({
              label: name,
              type: field_type,
              loading,
              error: errors?.extra_fields?.[idx]?.value,
              errorMessage: errors?.extra_fields?.[idx]?.value?.message,
              ...register(`extra_fields.${idx}.value`, {
                pattern: {
                  value: regexPatterns[field_type].value,
                  message: regexPatterns[field_type].message,
                },
              }),
            })}
          </Column>
        ))}
      </Row>
    </>
  )
}

export default CustomField
