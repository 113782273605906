import { Column, Popover, Row } from 'design-system'
import styled from 'styled-components'

const PopoverRatingsFilter = ({
  open,
  elementRef,
  options,
  setRateFilter,
  rateFilter,
  setOpenedState,
  ...props
}) => {
  const handleSelectAndClose = (value) => {
    setRateFilter((prevState) => (prevState === value ? '' : value))
    setOpenedState(false)
  }

  return (
    <Popover ref={elementRef} open={open} {...props}>
      <Column gap='4px' className='cursor-pointer'>
        {options.map(({ label, value }) => (
          <Item
            key={label}
            notSelectable
            active={rateFilter === value}
            onClick={() => handleSelectAndClose(value)}
          >
            {label}
          </Item>
        ))}
      </Column>
    </Popover>
  )
}

const Item = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #656565;
  font-size: 16px;
  transition: all 0.5s ease;
  padding: 8px;
  border-bottom: 1px solid #e8e8e8;
  background: ${({ active }) => (!!active ? '#edf3fc' : 'transparent')};
  user-select: none;

  &:hover {
    background: #edf3fc;
  }
`

export default PopoverRatingsFilter
