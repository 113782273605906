import { Icon, Row, Tooltip, Typography } from 'design-system'
import { useEffect, useRef, useState } from 'react'
import AudioWaveBars from './AudioWaveBars'
import { formatTime } from 'helpers'

const Ready = ({ audioUrl, currentTime, setCurrentTime }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const playerRef = useRef(null)

  const staticWaves = Array.from({ length: 32 }, () => Math.floor(Math.random() * 100))

  const play = () => {
    playerRef.current.play()

    setIsPlaying(true)
  }

  const pause = () => {
    playerRef.current.pause()
    setIsPlaying(false)
  }

  useEffect(() => {
    const createAudioUrl = () => {
      if (!audioUrl) return

      playerRef.current = new Audio(audioUrl)

      playerRef.current.addEventListener('timeupdate', () => {
        setCurrentTime(playerRef.current.currentTime)
      })
    }

    createAudioUrl()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl])

  return (
    <Row alignItems='center' gap='4px'>
      <Tooltip position='top' title='Ouvir áudio' width='120px'>
        <Icon
          icon={isPlaying ? 'Pause' : 'Play'}
          color='grey.300'
          onClick={isPlaying ? pause : play}
        />
      </Tooltip>
      <Typography color='grey.400'>{formatTime(currentTime)}</Typography>
      <AudioWaveBars staticWave={staticWaves} />
    </Row>
  )
}

export default Ready
