import { badgeInstagramLogo, badgeWhatsappLogo } from 'assets/logos/social'
import { defaultColorsTags } from './constants'
import { FacebookAlert } from 'assets/audios'
import {
  audioLogo,
  csvLogo,
  docLogo,
  mp3Logo,
  mp4Logo,
  pdfLogo,
  rarLogo,
  txtLogo,
  videoLogo,
  xlsLogo,
  xlsxLogo,
  xmlLogo,
  zipLogo,
} from 'assets'
import {
  badSurvey,
  neutralSurvey,
  slightlySurvey,
  smileySurvey,
  starStruckSurvey,
} from 'assets/components'
import colors from './colors'

export const connectionsTypeDict = {
  gupshup: {
    label: 'GupShup',
  },
}

export const platformTypeDict = {
  whatsapp: {
    label: 'WhatsApp',
    logo: badgeWhatsappLogo,
  },
  instagram: {
    label: 'Instragram',
    logo: badgeInstagramLogo,
  },
}

export const templateStatusDict = {
  APPROVED: {
    label: 'Aprovado',
    color: 'success',
  },
  PENDING: {
    label: 'Em validação',
    color: 'warning',
  },
  FAILED: {
    label: 'Não aprovado',
    color: 'danger',
  },
  REJECTED: {
    label: 'Rejeitado',
    color: 'danger',
  },
  PAUSED: {
    label: 'Pausado',
    color: 'text',
  },
  DELETED: {
    label: 'Deletado',
    color: 'text',
  },
}

export const buttonsTemplateDict = {
  COPY_CODE: {
    icon: 'Copy',
  },
  URL: { icon: 'Launch' },
  QUICK_REPLY: { icon: 'Share' },
  PHONE_NUMBER: { icon: 'Phone' },
}

export const modulePermissionsLabelDict = {
  companies: 'Permissões da empresa',
  connections: 'Permissões de conexão',
  templates: 'Permissões de template',
  tickets: 'Permissões de conversas',
  users: 'Permissões de usuários',
  quick_answers: 'Permissões de respostas rápidas',
  departments: 'Permissões de departamentos',
  roles: 'Permissões de cargos',
  contacts: 'Permissões de contatos',
  dashboard: 'Permissões dashboard',
}

export const fieldTypesDict = {
  text: 'Texto',
  boolean: 'Caixa de seleção',
  integer: 'Número inteiro',
  decimal: 'Número decimal',
}

export const inviteStatusDict = {
  pending: {
    label: 'Pendente',
    color: 'secondary',
  },
  accepted: {
    label: 'Aceito',
    color: 'success',
  },
  declined: {
    label: 'Rejeitado',
    color: 'danger',
  },
  canceled: {
    label: 'Cancelado',
    color: 'text',
  },
  expired: {
    label: 'Expirado',
    color: 'text',
  },
}

export const messageStatusDict = {
  '-1': {
    icon: 'Megaphone',
    value: -1,
    label: 'System',
  },
  0: {
    icon: 'Time',
    value: 0,
    label: 'Sending',
  },
  1: {
    icon: 'Check',
    value: 1,
    label: 'Sent',
  },
  2: {
    icon: 'CheckAll',
    value: 2,
    label: 'Delivered',
  },
  3: {
    icon: 'CheckAll',
    value: 3,
    label: 'Read',
    color: '#136CDC',
  },
  4: {
    icon: 'Close',
    value: 4,
    label: 'Error',
  },
}

export const textVariablesDict = {
  company: {
    color: defaultColorsTags[1],
  },
  attendant: {
    color: defaultColorsTags[5],
  },
  contact: {
    color: defaultColorsTags[4],
  },
  department: {
    color: defaultColorsTags[5],
  },
  connection: {
    color: defaultColorsTags[3],
  },
  ticket: {
    color: defaultColorsTags[5],
  },
}

export const lastMessageIconDict = {
  audio: 'Mic',
  pdf: 'File',
  video: 'VideoCam',
  image: 'PhotoCam',
  sticker: 'PhotoCam',
  other: 'File',
}

export const audioNotificationDict = {
  facebook: FacebookAlert,
}

export const ticketStatusDict = {
  attending: 'Em atendimento',
  pending: 'Aguardando',
  closed: 'Resolvido',
}

export const dashboardSurveyDict = {
  1: {
    emoji: badSurvey,
    label: 'Péssimo',
    color: colors.red[300],
    backgroundColor: colors.red[10],
  },
  2: {
    emoji: slightlySurvey,
    label: 'Ruim',
    color: colors.orange[400],
    backgroundColor: colors.orange[10],
  },
  3: {
    emoji: neutralSurvey,
    label: 'Neutro',
    color: colors.orange[300],
    backgroundColor: colors.orange[20],
  },
  4: {
    emoji: smileySurvey,
    label: 'Bom',
    color: colors.green[200],
    backgroundColor: colors.green[10],
  },
  5: {
    emoji: starStruckSurvey,
    label: 'Ótimo',
    color: colors.green[300],
    backgroundColor: colors.green[20],
  },
}

export const fileExtensionsDict = {
  'image/jpeg': {
    type: 'image',
    icon: null,
  },
  'image/png': {
    type: 'image',
    icon: null,
  },
  'image/gif': {
    type: 'image',
    icon: null,
  },
  'image/heif': {
    type: 'image',
    icon: null,
  },
  'application/pdf': {
    type: 'file',
    icon: pdfLogo,
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    type: 'file',
    icon: xlsxLogo,
  },

  'text/plain': {
    type: 'file',
    icon: txtLogo,
  },
  'application/vnd.oasis.opendocument.text': {
    type: 'file',
    icon: docLogo,
  },

  'application/vnd.oasis.opendocument.spreadsheet': {
    type: 'file',
    icon: xlsLogo,
  },
  'text/csv': {
    type: 'file',
    icon: csvLogo,
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    type: 'file',
    icon: xmlLogo,
  },
  'application/zip': {
    type: 'file',
    icon: zipLogo,
  },
  'application/rar': {
    type: 'file',
    icon: rarLogo,
  },
  'audio/ogg': {
    type: 'audio',
    icon: audioLogo,
  },
  'audio/mpeg': {
    type: 'audio',
    icon: mp3Logo,
  },
  'video/webm': {
    type: 'video',
    icon: videoLogo,
  },
  'video/mp4': {
    type: 'video',
    icon: mp4Logo,
  },
  'video/quicktime': {
    type: 'video',
    icon: null,
  },
}

export const satisfactionSurveyModeDict = {
  always: 'Envio automático',
  query: 'Envio manual',
  never: 'Nunca enviar',
}
