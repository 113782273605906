import { colors } from 'configs'
import { format } from 'date-fns'
import { Card, Column, Icon, Row, Typography } from 'design-system'
import { formatMessageText } from 'helpers'

const PreviewTemplateMessage = ({ message, icon, ...props }) => (
  <Column p='24px' borderRadius='8px' background={colors.grey[50]} {...props}>
    <Card background={colors.violet[100]} padding='0px'>
      <Column p='8px'>
        <Typography>{formatMessageText(message)}</Typography>
        <Row justifyContent='flex-end' alignItems='center' gap='4px'>
          <Typography fontSize='11px' color='grey.200'>
            {format(new Date(), 'HH:mm')}
          </Typography>
          <Icon icon='CheckAll' size='sm' color='grey.200' />
        </Row>
      </Column>
      {icon && (
        <Row p='10px 24px' justifyContent='center' alignItems='center' borderTop='1px solid white'>
          <Icon icon={icon} color='grey.400' />
        </Row>
      )}
    </Card>
  </Column>
)

PreviewTemplateMessage.defaultProps = {
  message: 'Template de mensagem',
  icon: null,
}

export default PreviewTemplateMessage
