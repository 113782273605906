import { ModalQuickAnswersSend } from 'components'
import PopoverEmoji from 'components/popovers/PopoverEmoji'
import { Icon, Row, Switch, Typography, Tooltip } from 'design-system'
import { useClickout } from 'hooks'
import { useState } from 'react'

const MessageOptions = ({
  setValue,
  getValues,
  handlerSigned,
  signed,
  ticket,
  isBlockedInteraction,
  fileInputId,
  setVoiceMessage,
}) => {
  const [quickAnswers, setQuickAnswers] = useState(false)

  const { triggerRef, elementRef, openedState } = useClickout(false)

  return (
    <Row position='relative' alignItems='center' gap='8px'>
      <Row position='relative'>
        <Icon
          ref={triggerRef}
          size='lg'
          icon='Emoji'
          color={isBlockedInteraction ? 'grey.50' : 'grey.300'}
          cursor={isBlockedInteraction ? 'default' : 'pointer'}
        />
        <PopoverEmoji
          elementRef={elementRef}
          bottom='165px'
          open={!isBlockedInteraction && openedState}
          onEmojiSelect={(value) => setValue('body', `${getValues('body')}${value}`)}
        />
      </Row>
      <Typography as='label' lineHeight='0' htmlFor={isBlockedInteraction ? null : fileInputId}>
        <Icon
          icon='Clip'
          size='lg'
          color={isBlockedInteraction ? 'grey.50' : 'grey.300'}
          cursor={isBlockedInteraction ? 'default' : 'pointer'}
        />
      </Typography>
      <Tooltip title={!isBlockedInteraction && 'Respostas rápidas'} whiteSpace='nowrap'>
        <Icon
          icon='Chat'
          size='lg'
          color={isBlockedInteraction ? 'grey.50' : 'grey.300'}
          {...(!isBlockedInteraction && {
            cursor: 'pointer',
            onClick: () => setQuickAnswers(!quickAnswers),
          })}
        />
      </Tooltip>
      <Tooltip>
        <Icon
          icon='Mic'
          size='lg'
          color={isBlockedInteraction ? 'grey.50' : 'grey.300'}
          {...(!isBlockedInteraction && {
            cursor: 'pointer',
            onClick: () => {
              setValue('body', '')
              setVoiceMessage(true)
            },
          })}
        />
      </Tooltip>
      <Row gap='4px'>
        <Switch
          disabled={isBlockedInteraction}
          checked={signed}
          onChange={(e) => handlerSigned(e.target.checked)}
        />
        <Typography color={isBlockedInteraction ? 'grey.50' : 'grey.300'}>Assinar</Typography>
      </Row>
      {quickAnswers && (
        <ModalQuickAnswersSend
          ticketId={ticket.id}
          changeInput={(value) => setValue('body', value)}
          quickAnswers={quickAnswers}
          onClose={() => setQuickAnswers(null)}
        />
      )}
    </Row>
  )
}

export default MessageOptions
