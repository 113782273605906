import { useEffect, useState } from 'react'

import {
  Card,
  CheckboxDayCard,
  Column,
  InputMasked,
  Row,
  Typography,
  WeekTimer,
} from 'design-system'

import { colors, weekDays } from 'configs'

const WorkdaysSelector = ({ initialValue, name, loading, control, setValue, errors, register }) => {
  const [selectedDays, setSelectedDays] = useState([])
  const [defaultTime, setDefaultTime] = useState({ start: '', end: '' })

  useEffect(() => {
    setSelectedDays(() => initialValue.filter((f) => f.selected))
  }, [initialValue])

  useEffect(() => {
    if (defaultTime?.start) {
      selectedDays.forEach((day) => {
        setValue(`${name}.${day.weekday}.start`, defaultTime.start)
      })
    }

    if (defaultTime?.end) {
      selectedDays.forEach((day) => {
        setValue(`${name}.${day.weekday}.end`, defaultTime.end)
      })
    }
  }, [defaultTime.start, defaultTime.end, setValue, selectedDays, name])

  const handleChange = (day) => {
    if (selectedDays.find((d) => d.weekday === day.weekday)) {
      const remove = selectedDays.filter((d) => d.weekday !== day.weekday)
      setValue(`${name}.${day.weekday}.selected`, false)
      return setSelectedDays(() => remove)
    }
    setValue(`${name}.${day.weekday}.selected`, true)
    setSelectedDays((prevState) => [...prevState, day])
  }

  return (
    <Card border={`1px solid ${errors?.working_schedules ? colors.red[300] : colors.grey[50]}`}>
      <Typography variant='subtitle'>Configurar horário de atendimento</Typography>
      <Typography mt='16px'>
        Selecione os dias de atendimento marcando as caixas correspondentes. Após selecionar, os
        campos para definir os horários aparecerão para preencher.
      </Typography>
      <Row mt='16px' gap='8px' flexWrap='wrap'>
        {weekDays.map((day) => (
          <CheckboxDayCard
            active={selectedDays.find((d) => d.weekday === day.weekday)}
            day={day}
            onClick={handleChange}
          />
        ))}
      </Row>
      <Column mt='16px' gap='8px'>
        {selectedDays.length > 1 && (
          <Row gap='6px'>
            <Row width='110px'>
              <Typography>Ajustar todos</Typography>
            </Row>
            <InputMasked
              value={defaultTime?.start}
              onChange={(e) => setDefaultTime({ ...defaultTime, start: e.target.value })}
              maxWidth='100px'
              mask='99:99'
              disabled={loading}
              textAlign='center'
            />
            <InputMasked
              value={defaultTime?.end}
              onChange={(e) => setDefaultTime({ ...defaultTime, end: e.target.value })}
              maxWidth='100px'
              mask='99:99'
              disabled={loading}
              textAlign='center'
            />
          </Row>
        )}
        {selectedDays
          .sort((dayA, dayB) => (dayB.weekday < dayA.weekday ? 1 : -1))
          .map((field, idx) => (
            <Column>
              <WeekTimer
                key={field.id}
                name={name}
                index={idx}
                field={field}
                control={control}
                loading={loading}
                errors={errors}
                register={register}
              />
            </Column>
          ))}
      </Column>
      {errors[name]?.length > 0 && (
        <Typography mt='16px' color='danger'>
          * Existem horários inválidos na sua configuração.
        </Typography>
      )}
    </Card>
  )
}

WorkdaysSelector.defaultProps = {
  initialValue: [],
}

export default WorkdaysSelector
