import { colors } from 'configs'
import { Card, Column, Icon, Row, Typography } from 'design-system'

const CardValue = ({ title, value, description, icon, labelProps, ...props }) => {
  return (
    <Card width={['100%', '100%', '360px', '360px']} {...props}>
      <Row gap='16px' alignItems='center'>
        {icon && (
          <Column background={colors.grey[50]} p='12px' borderRadius='8px'>
            <Icon icon={icon} color='grey.500' />
          </Column>
        )}
        <Column>
          <Typography>{title}</Typography>
          <Row alignItems='center' gap='4px'>
            <Typography fontSize='18px' fontWeight='600' color='grey.500' {...labelProps}>
              {value}
            </Typography>
            {description && (
              <Typography fontSize='12px' fontWeight='600' color='secondary'>
                {description}
              </Typography>
            )}
          </Row>
        </Column>
      </Row>
    </Card>
  )
}

CardValue.defaultProps = {
  title: 'Título',
  value: 'valor',
  icon: null,
}

export default CardValue
