import { parsePhoneNumber } from 'libphonenumber-js'

const normalizePhoneNumber = (phoneNumber) => {
  try {
    if (!phoneNumber) return { country_code: null, phone: null, original: null }

    const phoneNumberObj = parsePhoneNumber(phoneNumber)

    return {
      country_code: phoneNumberObj.countryCallingCode,
      phone: phoneNumberObj.nationalNumber,
      original: `+${phoneNumberObj.countryCallingCode}${phoneNumberObj.nationalNumber}`,
    }
  } catch (error) {
    throw new Error('Número de telefone inválido')
  }
}

export default normalizePhoneNumber
