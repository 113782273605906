import { textVariablesDict } from 'configs'
import { Badge, Column, Icon, Popover, Typography } from 'design-system'
import { notify } from 'helpers'
import { useEffect, useState } from 'react'
import { showTextVariables } from 'services'

const PopoverTextVariables = ({
  open,
  elementRef,
  setValue,
  getValues,
  onTextSelect,
  ...props
}) => {
  const [textVariables, setTextVariables] = useState([])

  useEffect(() => {
    handleGetTextVariables()
  }, [])

  const handleGetTextVariables = async () => {
    try {
      const { data } = await showTextVariables({ page_size: 1000 })

      setTextVariables(data.results)
    } catch {
      notify.error('Não foi possível buscar lista de variáveis.')
    }
  }

  const ordering = (a, b) => {
    if (a.category > b.category) return 1
    if (a.category < b.category) return -1
    if (a.name > b.name) return 1
    if (a.name < b.name) return -1
    return 0
  }

  return (
    <Popover ref={elementRef} open={open} minWidth='250px' {...props}>
      <Column p='8px 16px'>
        <Typography fontWeight='600' fontSize='14px'>
          Variáveis de texto
        </Typography>
        <Column mt='8px' gap='8px' overflow='auto' height='300px'>
          {textVariables.length > 0 &&
            textVariables
              .sort((a, b) => ordering(a, b))
              ?.map((text) => (
                <Badge
                  key={text?.variable}
                  backgroundColor={textVariablesDict[text.category].color}
                  color='#656565'
                  onClick={() => onTextSelect(text.variable)}
                >
                  {text.name}
                  <Icon size='11' icon='Plus' color='#656565' />
                </Badge>
              ))}
        </Column>
      </Column>
    </Popover>
  )
}

export default PopoverTextVariables
