import { colors } from 'configs'
import { Column, Row, Skeleton } from 'design-system'

const SkeletonList = () => {
  return (
    <Column>
      <Column p='8px 24px' borderBottom={`1px solid ${colors.grey[50]}`}>
        <Row justifyContent='space-between'>
          <Skeleton width='120px' />
          <Skeleton width='80px' />
        </Row>
        <Skeleton mt='8px' />
      </Column>
      <Column p='8px 24px' borderBottom={`1px solid ${colors.grey[50]}`}>
        <Row justifyContent='space-between'>
          <Skeleton width='120px' />
          <Skeleton width='80px' />
        </Row>
        <Skeleton mt='8px' />
      </Column>
      <Column p='8px 24px' borderBottom={`1px solid ${colors.grey[50]}`}>
        <Row justifyContent='space-between'>
          <Skeleton width='120px' />
          <Skeleton width='80px' />
        </Row>
        <Skeleton mt='8px' />
      </Column>
      <Column p='8px 24px' borderBottom={`1px solid ${colors.grey[50]}`}>
        <Row justifyContent='space-between'>
          <Skeleton width='120px' />
          <Skeleton width='80px' />
        </Row>
        <Skeleton mt='8px' />
      </Column>
      <Column p='8px 24px' borderBottom={`1px solid ${colors.grey[50]}`}>
        <Row justifyContent='space-between'>
          <Skeleton width='120px' />
          <Skeleton width='80px' />
        </Row>
        <Skeleton mt='8px' />
      </Column>
      <Column p='8px 24px' borderBottom={`1px solid ${colors.grey[50]}`}>
        <Row justifyContent='space-between'>
          <Skeleton width='120px' />
          <Skeleton width='80px' />
        </Row>
        <Skeleton mt='8px' />
      </Column>
    </Column>
  )
}

export default SkeletonList
