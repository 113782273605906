import { memo } from 'react'

const Play = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <mask x='0' y='0' width='24' height='24'>
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g>
        <path d='M8 19V5L19 12L8 19Z' fill='#656565' />
      </g>
    </svg>
  )
}
const Memo = memo(Play)
export default Memo
