import { Avatar, Card, Column, Row, Typography } from 'design-system'

const User = ({ user }) => {
  const { picture, name } = user.company_user || {}

  return (
    <Card backgroundColor='grey.40'>
      <Row gap='8px' alignItems='center'>
        <Avatar src={picture} square size='md' alt={name} />
        <Column>
          <Typography fontWeight='600'>{name}</Typography>
        </Column>
      </Row>
    </Card>
  )
}

export default User
