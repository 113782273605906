import { memo, forwardRef } from 'react'

const AlarmCheck = forwardRef((props, ref) => {
  return (
    <svg ref={ref} width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <mask id='mask0_4003_25125' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_4003_25125)'>
        <path
          d='M10.9477 16.5496L16.5977 10.8996L15.1727 9.47461L10.9477 13.6996L8.82266 11.5746L7.39766 12.9996L10.9477 16.5496ZM11.9977 21.9996C10.7477 21.9996 9.57682 21.7621 8.48516 21.2871C7.39349 20.8121 6.44349 20.1704 5.63516 19.3621C4.82682 18.5538 4.18516 17.6038 3.71016 16.5121C3.23516 15.4204 2.99766 14.2496 2.99766 12.9996C2.99766 11.7496 3.23516 10.5788 3.71016 9.48711C4.18516 8.39544 4.82682 7.44544 5.63516 6.63711C6.44349 5.82878 7.39349 5.18711 8.48516 4.71211C9.57682 4.23711 10.7477 3.99961 11.9977 3.99961C13.2477 3.99961 14.4185 4.23711 15.5102 4.71211C16.6018 5.18711 17.5518 5.82878 18.3602 6.63711C19.1685 7.44544 19.8102 8.39544 20.2852 9.48711C20.7602 10.5788 20.9977 11.7496 20.9977 12.9996C20.9977 14.2496 20.7602 15.4204 20.2852 16.5121C19.8102 17.6038 19.1685 18.5538 18.3602 19.3621C17.5518 20.1704 16.6018 20.8121 15.5102 21.2871C14.4185 21.7621 13.2477 21.9996 11.9977 21.9996ZM5.59766 2.34961L6.99766 3.74961L2.74766 7.99961L1.34766 6.59961L5.59766 2.34961ZM18.3977 2.34961L22.6477 6.59961L21.2477 7.99961L16.9977 3.74961L18.3977 2.34961Z'
          fill='#3E3E3E'
        />
      </g>
    </svg>
  )
})
const Memo = memo(AlarmCheck)
export default Memo
