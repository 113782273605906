import { memo } from 'react'

const Pause = (props) => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' {...props}>
      <path d='M14.7188 19V5H18.7188V19H14.7188ZM6.71875 19V5H10.7188V19H6.71875Z' fill='#656565' />
    </svg>
  )
}
const Memo = memo(Pause)
export default Memo
