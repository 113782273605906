import React, { forwardRef, memo } from 'react'
import styled from 'styled-components'
import { color, layout, compose, space, border } from 'styled-system'

import * as Icons from 'assets/icons'
import { getThemeColor } from 'helpers'

import { variants } from './variants'

const styles = compose(color, layout, space, border)

const IconSelect = forwardRef(({ icon, ...props }, ref) => {
  const SelectedIcon = Icons[icon]

  return SelectedIcon ? (
    <SelectedIcon ref={ref} {...props} />
  ) : (
    <Icons.Search ref={ref} {...props} />
  )
})

const Icon = styled(IconSelect)`
  * {
    fill: ${({ color, theme }) => getThemeColor(color, theme)};
  }

  ${variants}
  ${styles}
`

Icon.defaultProps = {
  color: 'primary',
  size: 'md',
}

export default memo(Icon)
