export const countryCodes = [
  { label: 'Afeganistão +93', code: '93' },
  { label: 'Albânia +355', code: '355' },
  { label: 'Argélia +213', code: '213' },
  { label: 'Samoa Americana +1-684', code: '1-684' },
  { label: 'Andorra +376', code: '376' },
  { label: 'Angola +244', code: '244' },
  { label: 'Anguila +1-264', code: '1-264' },
  { label: 'Antártica +672', code: '672' },
  { label: 'Antígua e Barbuda +1-268', code: '1-268' },
  { label: 'Argentina +54', code: '54' },
  { label: 'Armênia +374', code: '374' },
  { label: 'Aruba +297', code: '297' },
  { label: 'Austrália +61', code: '61' },
  { label: 'Áustria +43', code: '43' },
  { label: 'Azerbaijão +994', code: '994' },
  { label: 'Bahamas +1-242', code: '1-242' },
  { label: 'Bahrein +973', code: '973' },
  { label: 'Bangladesh +880', code: '880' },
  { label: 'Barbados +1-246', code: '1-246' },
  { label: 'Bielorrússia +375', code: '375' },
  { label: 'Bélgica +32', code: '32' },
  { label: 'Belize +501', code: '501' },
  { label: 'Benin +229', code: '229' },
  { label: 'Bermudas +1-441', code: '1-441' },
  { label: 'Butão +975', code: '975' },
  { label: 'Bolívia +591', code: '591' },
  { label: 'Bonaire, Sint Eustatius e Saba +599', code: '599' },
  { label: 'Bósnia e Herzegovina +387', code: '387' },
  { label: 'Botswana +267', code: '267' },
  { label: 'Ilha Bouvet +47', code: '47' },
  { label: 'Brasil +55', code: '55' },
  { label: 'Território Britânico do Oceano Índico +246', code: '246' },
  { label: 'Brunei Darussalam +673', code: '673' },
  { label: 'Bulgária +359', code: '359' },
  { label: 'Burkina Faso +226', code: '226' },
  { label: 'Burundi +257', code: '257' },
  { label: 'Cabo Verde +238', code: '238' },
  { label: 'Camboja +855', code: '855' },
  { label: 'Camarões +237', code: '237' },
  { label: 'Canadá +1', code: '1' },
  { label: 'Ilhas Cayman +1-345', code: '1-345' },
  { label: 'República Centro-Africana +236', code: '236' },
  { label: 'Chade +235', code: '235' },
  { label: 'Chile +56', code: '56' },
  { label: 'China +86', code: '86' },
  { label: 'Ilha Christmas +61', code: '61' },
  { label: 'Ilhas Cocos +61', code: '61' },
  { label: 'Colômbia +57', code: '57' },
  { label: 'Comores +269', code: '269' },
  { label: 'República Democrática do Congo +243', code: '243' },
  { label: 'Congo +242', code: '242' },
  { label: 'Ilhas Cook +682', code: '682' },
  { label: 'Costa Rica +506', code: '506' },
  { label: 'Croácia +385', code: '385' },
  { label: 'Cuba +53', code: '53' },
  { label: 'Curaçao +599', code: '599' },
  { label: 'Chipre +357', code: '357' },
  { label: 'Tchéquia +420', code: '420' },
  { label: 'Costa do Marfim +225', code: '225' },
  { label: 'Dinamarca +45', code: '45' },
  { label: 'Djibouti +253', code: '253' },
  { label: 'Dominica +1-767', code: '1-767' },
  { label: 'República Dominicana +1-809', code: '1-809' },
  { label: 'Equador +593', code: '593' },
  { label: 'Egito +20', code: '20' },
  { label: 'El Salvador +503', code: '503' },
  { label: 'Guiné Equatorial +240', code: '240' },
  { label: 'Eritreia +291', code: '291' },
  { label: 'Estônia +372', code: '372' },
  { label: 'Eswatini +268', code: '268' },
  { label: 'Etiópia +251', code: '251' },
  { label: 'Ilhas Falkland +500', code: '500' },
  { label: 'Ilhas Faroe +298', code: '298' },
  { label: 'Fiji +679', code: '679' },
  { label: 'Finlândia +358', code: '358' },
  { label: 'França +33', code: '33' },
  { label: 'Guiana Francesa +594', code: '594' },
  { label: 'Polinésia Francesa +689', code: '689' },
  { label: 'Terras Austrais e Antárticas Francesas +262', code: '262' },
  { label: 'Gabão +241', code: '241' },
  { label: 'Gâmbia +220', code: '220' },
  { label: 'Geórgia +995', code: '995' },
  { label: 'Alemanha +49', code: '49' },
  { label: 'Gana +233', code: '233' },
  { label: 'Gibraltar +350', code: '350' },
  { label: 'Grécia +30', code: '30' },
  { label: 'Groenlândia +299', code: '299' },
  { label: 'Granada +1-473', code: '1-473' },
  { label: 'Guadalupe +590', code: '590' },
  { label: 'Guam +1-671', code: '1-671' },
  { label: 'Guatemala +502', code: '502' },
  { label: 'Guernsey +44-1481', code: '44-1481' },
  { label: 'Guiné +224', code: '224' },
  { label: 'Guiné-Bissau +245', code: '245' },
  { label: 'Guiana +592', code: '592' },
  { label: 'Haiti +509', code: '509' },
  { label: 'Ilha Heard e Ilhas McDonald +672', code: '672' },
  { label: 'Santa Sé (Vaticano) +39-06', code: '39-06' },
  { label: 'Honduras +504', code: '504' },
  { label: 'Hong Kong +852', code: '852' },
  { label: 'Hungria +36', code: '36' },
  { label: 'Islândia +354', code: '354' },
  { label: 'Índia +91', code: '91' },
  { label: 'Indonésia +62', code: '62' },
  { label: 'Irã +98', code: '98' },
  { label: 'Iraque +964', code: '964' },
  { label: 'Irlanda +353', code: '353' },
  { label: 'Ilha de Man +44-1624', code: '44-1624' },
  { label: 'Israel +972', code: '972' },
  { label: 'Itália +39', code: '39' },
  { label: 'Jamaica +1-876', code: '1-876' },
  { label: 'Japão +81', code: '81' },
  { label: 'Jersey +44-1534', code: '44-1534' },
  { label: 'Jordânia +962', code: '962' },
  { label: 'Cazaquistão +7', code: '7' },
  { label: 'Quênia +254', code: '254' },
  { label: 'Kiribati +686', code: '686' },
  { label: 'Coreia do Norte +850', code: '850' },
  { label: 'Coreia do Sul +82', code: '82' },
  { label: 'Kuwait +965', code: '965' },
  { label: 'Quirguistão +996', code: '996' },
  { label: 'Laos +856', code: '856' },
  { label: 'Letônia +371', code: '371' },
  { label: 'Líbano +961', code: '961' },
  { label: 'Lesoto +266', code: '266' },
  { label: 'Libéria +231', code: '231' },
  { label: 'Líbia +218', code: '218' },
  { label: 'Liechtenstein +423', code: '423' },
  { label: 'Lituânia +370', code: '370' },
  { label: 'Luxemburgo +352', code: '352' },
  { label: 'Macau +853', code: '853' },
  { label: 'Macedônia do Norte +389', code: '389' },
  { label: 'Madagascar +261', code: '261' },
  { label: 'Malawi +265', code: '265' },
  { label: 'Malásia +60', code: '60' },
  { label: 'Maldivas +960', code: '960' },
  { label: 'Mali +223', code: '223' },
  { label: 'Malta +356', code: '356' },
  { label: 'Ilhas Marshall +692', code: '692' },
  { label: 'Martinica +596', code: '596' },
  { label: 'Mauritânia +222', code: '222' },
  { label: 'Maurício +230', code: '230' },
  { label: 'Mayotte +262', code: '262' },
  { label: 'México +52', code: '52' },
  { label: 'Micronésia +691', code: '691' },
  { label: 'Moldávia +373', code: '373' },
  { label: 'Mônaco +377', code: '377' },
  { label: 'Mongólia +976', code: '976' },
  { label: 'Montenegro +382', code: '382' },
  { label: 'Montserrat +1-664', code: '1-664' },
  { label: 'Marrocos +212', code: '212' },
  { label: 'Moçambique +258', code: '258' },
  { label: 'Mianmar +95', code: '95' },
  { label: 'Namíbia +264', code: '264' },
  { label: 'Nauru +674', code: '674' },
  { label: 'Nepal +977', code: '977' },
  { label: 'Países Baixos +31', code: '31' },
  { label: 'Nova Caledônia +687', code: '687' },
  { label: 'Nova Zelândia +64', code: '64' },
  { label: 'Nicarágua +505', code: '505' },
  { label: 'Níger +227', code: '227' },
  { label: 'Nigéria +234', code: '234' },
  { label: 'Niue +683', code: '683' },
  { label: 'Ilha Norfolk +672', code: '672' },
  { label: 'Ilhas Marianas do Norte +1-670', code: '1-670' },
  { label: 'Noruega +47', code: '47' },
  { label: 'Omã +968', code: '968' },
  { label: 'Paquistão +92', code: '92' },
  { label: 'Palau +680', code: '680' },
  { label: 'Palestina +970', code: '970' },
  { label: 'Panamá +507', code: '507' },
  { label: 'Papua Nova Guiné +675', code: '675' },
  { label: 'Paraguai +595', code: '595' },
  { label: 'Peru +51', code: '51' },
  { label: 'Filipinas +63', code: '63' },
  { label: 'Pitcairn +64', code: '64' },
  { label: 'Polônia +48', code: '48' },
  { label: 'Portugal +351', code: '351' },
  { label: 'Porto Rico +1-787', code: '1-787' },
  { label: 'Catar +974', code: '974' },
  { label: 'Romênia +40', code: '40' },
  { label: 'Rússia +7', code: '7' },
  { label: 'Ruanda +250', code: '250' },
  { label: 'Reunião +262', code: '262' },
  { label: 'Saint Barthélemy +590', code: '590' },
  { label: 'Santa Helena, Ascensão e Tristão da Cunha +290', code: '290' },
  { label: 'Saint Kitts e Nevis +1-869', code: '1-869' },
  { label: 'Santa Lúcia +1-758', code: '1-758' },
  { label: 'Saint Martin +590', code: '590' },
  { label: 'Saint Pierre e Miquelon +508', code: '508' },
  { label: 'Saint Vincent e Granadinas +1-784', code: '1-784' },
  { label: 'Samoa +685', code: '685' },
  { label: 'San Marino +378', code: '378' },
  { label: 'São Tomé e Príncipe +239', code: '239' },
  { label: 'Arábia Saudita +966', code: '966' },
  { label: 'Senegal +221', code: '221' },
  { label: 'Sérvia +381', code: '381' },
  { label: 'Seychelles +248', code: '248' },
  { label: 'Serra Leoa +232', code: '232' },
  { label: 'Singapura +65', code: '65' },
  { label: 'Sint Maarten +1-721', code: '1-721' },
  { label: 'Eslováquia +421', code: '421' },
  { label: 'Eslovênia +386', code: '386' },
  { label: 'Ilhas Salomão +677', code: '677' },
  { label: 'Somália +252', code: '252' },
  { label: 'África do Sul +27', code: '27' },
  { label: 'Geórgia do Sul e Ilhas Sandwich +500', code: '500' },
  { label: 'Sudão do Sul +211', code: '211' },
  { label: 'Espanha +34', code: '34' },
  { label: 'Sri Lanka +94', code: '94' },
  { label: 'Sudão +249', code: '249' },
  { label: 'Suriname +597', code: '597' },
  { label: 'Svalbard e Jan Mayen +47', code: '47' },
  { label: 'Suazilândia +268', code: '268' },
  { label: 'Suécia +46', code: '46' },
  { label: 'Suíça +41', code: '41' },
  { label: 'Síria +963', code: '963' },
  { label: 'Taiwan +886', code: '886' },
  { label: 'Tajiquistão +992', code: '992' },
  { label: 'Tanzânia +255', code: '255' },
  { label: 'Tailândia +66', code: '66' },
  { label: 'Timor-Leste +670', code: '670' },
  { label: 'Togo +228', code: '228' },
  { label: 'Tokelau +690', code: '690' },
  { label: 'Tonga +676', code: '676' },
  { label: 'Trinidad e Tobago +1-868', code: '1-868' },
  { label: 'Tunísia +216', code: '216' },
  { label: 'Turquia +90', code: '90' },
  { label: 'Turcomenistão +993', code: '993' },
  { label: 'Tuvalu +688', code: '688' },
  { label: 'Uganda +256', code: '256' },
  { label: 'Ucrânia +380', code: '380' },
  { label: 'Emirados Árabes Unidos +971', code: '971' },
  { label: 'Reino Unido +44', code: '44' },
  { label: 'Estados Unidos +1', code: '1' },
  { label: 'Uruguai +598', code: '598' },
  { label: 'Uzbequistão +998', code: '998' },
  { label: 'Vanuatu +678', code: '678' },
  { label: 'Vaticano +39', code: '39' },
  { label: 'Venezuela +58', code: '58' },
  { label: 'Vietnã +84', code: '84' },
  { label: 'Ilhas Virgens (Britânicas) +1-284', code: '1-284' },
  { label: 'Ilhas Virgens (EUA) +1-340', code: '1-340' },
  { label: 'Wallis e Futuna +681', code: '681' },
  { label: 'Saara Ocidental +212', code: '212' },
  { label: 'Iémen +967', code: '967' },
  { label: 'Zâmbia +260', code: '260' },
  { label: 'Zimbábue +263', code: '263' },
]
