import { variant } from 'styled-system'

import { hexToRGBA } from 'helpers'

const colorVariants = ({ theme: { colors }, color = 'primary' }) =>
  variant({
    prop: 'variant',
    variants: {
      filled: {
        boxShadow: '0px 4px 8px 0px rgba(14, 30, 47, 0.03)',
        bg: color,
        color: '#fff',
        '&:hover': {
          bg: hexToRGBA(colors[color], 0.9),
        },
        '& svg path': {
          fill: '#fff',
        },
        '&:disabled': {
          bg: colors.grey[300],
          borderColor: colors.grey[300],
          cursor: 'not-allowed',
        },
      },
      outlined: {
        boxShadow: '0px 4px 8px 0px rgba(14, 30, 47, 0.03)',
        bg: 'transparent',
        borderColor: color,
        '&:hover': {
          bg: hexToRGBA(colors[color], 0.1),
        },
        '& > svg path': {
          fill: color,
        },
        '&:disabled, &:disabled svg path': {
          bg: 'transparent',
          borderColor: colors.grey[300],
          cursor: 'not-allowed',
          color: colors.grey[300],
          fill: colors.grey[300],
        },
      },
      text: {
        bg: 'transparent',
        '& svg path': {
          fill: color,
        },
        border: 'none',
        '&:hover, &:hover svg path': {
          color: hexToRGBA(colors[color], 0.7),
          fill: hexToRGBA(colors[color], 0.7),
        },
        '&:disabled, &:disabled svg path': {
          color: colors.grey[300],
          fill: colors.grey[300],
          cursor: 'not-allowed',
        },
      },
      icon: {
        boxShadow: '0px 4px 8px 0px rgba(14, 30, 47, 0.03)',
        bg: 'white',
        width: '34px',
        height: '32px',
        borderRadius: '8px',
        padding: '10px',
        border: `1px solid ${colors.primary}`,
        '&:hover, &:hover svg path': {
          bg: hexToRGBA(colors[color], 0.1),
        },
        '&:disabled, &:disabled svg path': {
          color: colors.grey[300],
          fill: colors.grey[300],
          cursor: 'not-allowed',
        },
      },
      pagination: {
        width: '32px',
        height: '32px',
        borderRadius: '5px',
        padding: '10px',
        border: `1px solid ${colors.purple[100]}`,
        '&:hover, &:hover svg path': {
          border: `1px solid ${colors.purple[100]}`,
          bg: colors.purple[100],
          fill: '#fff',
          color: '#fff',
        },
        '&:disabled, &:disabled svg path': {
          border: `1px solid ${colors.grey[75]}`,
          bg: colors.grey[75],
          fill: '#fff',
          cursor: 'not-allowed',
        },
      },
    },
  })

const sizeVariants = () =>
  variant({
    prop: 'size',
    variants: {
      sm: {
        height: '22px',
        fontSize: '12px',
      },
      md: {
        height: '32px',
        fontSize: '14px',
      },
      icon: {
        width: '32px',
        height: '32px',
      },
    },
  })

export { colorVariants, sizeVariants }
