import TicketMessage from 'components/specific/tickets/TicketMessage'

const customMessage = (message) => ({
  body: { text: message?.text || '' },
  sender_type: 'system',
  ...message,
})

const CustomMessage = ({ message }) => <TicketMessage message={customMessage(message)} />

export default CustomMessage
