import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Column, Form, Input, Row, Typography } from 'design-system'
import { requestResetPassword, resetPassword } from 'services'
import { notify } from 'helpers'
import { passwordRegex } from 'utils'
import { ResetPasswordInfos } from 'components'

const ResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState({})
  const [info, setInfo] = useState(null)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const navigate = useNavigate()
  const params = useParams()

  const hasToken = watch('token')
  const watchPassword = watch('password')

  useEffect(() => {
    if (params?.token) {
      setValue('token', params?.token)
    }
  }, [params, setValue])

  const onSubmitRequest = async (values) => {
    try {
      setLoading(true)
      setInfo(null)

      await requestResetPassword({ ...values })

      setInfo({
        label: 'Solicitação enviada com sucesso! Verifique seu e-mail e siga as instruções.',
        type: 'success',
      })

      notify.success('Solicitação enviada com sucesso.')
    } catch (err) {
      setInfo({ label: err.response.data.email, type: 'danger', error: true })
    } finally {
      setLoading(false)
    }
  }

  const onSubmitReset = async (values) => {
    try {
      setLoading(true)
      setInfo(null)

      await resetPassword({ ...values })

      setInfo({
        label: 'Sua senha foi alterada com sucesso.',
        type: 'success',
        success: true,
      })

      notify.success('Sua senha foi alterada com sucesso.')
      navigate('/login')
    } catch (err) {
      if (err.response.data.detail) {
        setInfo({
          label:
            'A senha parece inválida. Tente outra. Caso o erro persista, entre em contato com o suporte.',
          type: 'danger',
          error: true,
        })
      }
      notify.error('Não foi possível alterar sua senha.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Column
      alignItems='center'
      justifyContent='center'
      height='100%'
      p='20px'
      minHeight='100vh'
      backgroundColor='rgb(99,81,172)'
      backgroundImage='linear-gradient(90deg, rgba(17,23,42,1) 0%, rgba(99,81,172,1) 52%, rgba(17,23,42,1) 95%)'
    >
      <Form
        maxWidth='380px'
        p='30px'
        border='1px solid #E8E8E8'
        borderRadius='5px'
        backgroundColor='#fff'
        onSubmit={handleSubmit(hasToken ? onSubmitReset : onSubmitRequest)}
        gap='24px'
      >
        {hasToken ? (
          <Column gap='16px'>
            <Typography fontSize='30px' textAlign='center' fontWeight='600'>
              Alterar senha
            </Typography>
            <Input
              label='Nova senha'
              type={showPassword?.password ? 'text' : 'password'}
              placeholder='Digite sua senha'
              icon={showPassword?.password ? 'Eye' : 'ClosedEye'}
              error={errors?.password}
              errorMessage={errors?.password?.message}
              iconProps={{
                onClick: () =>
                  setShowPassword((prevState) => ({
                    ...prevState,
                    password: !prevState?.password,
                  })),
              }}
              {...register('password', {
                required: true,
                pattern: {
                  value: passwordRegex,
                  message: 'Senha inválida',
                },
              })}
            />
            <Input
              label='Repetir senha'
              type={showPassword?.confirm_password ? 'text' : 'password'}
              placeholder='Confirmação'
              icon={showPassword?.confirm_password ? 'Eye' : 'ClosedEye'}
              error={errors?.confirm_password}
              errorMessage={errors?.confirm_password?.message}
              iconProps={{
                onClick: () =>
                  setShowPassword((prevState) => ({
                    ...prevState,
                    confirm_password: !prevState?.confirm_password,
                  })),
              }}
              {...register('confirm_password', {
                required: true,
                pattern: {
                  value: passwordRegex,
                  message: 'Senha inválida',
                },
                validate: (value) => {
                  if (value !== watchPassword) {
                    return 'As senhas precisam ser igual.'
                  }
                },
              })}
            />
          </Column>
        ) : (
          <Column gap='16px'>
            <Typography fontSize='30px' textAlign='center' fontWeight='600'>
              Recuperar senha
            </Typography>
            <Typography fontWeight='600'>
              Informe o e-mail de cadastro para recuperar sua senha
            </Typography>
            <Column>
              <Input
                placeholder='Digite seu e-mail'
                icon='Email'
                iconProps={{ color: 'grey.100' }}
                {...register('email')}
              />
            </Column>
          </Column>
        )}
        {info && (
          <Row>
            <Typography variant='caption' color={info.type}>
              {info.label}
            </Typography>
          </Row>
        )}
        {(info?.error || errors?.password || errors?.confirm_password) && <ResetPasswordInfos />}
        {info?.success ? (
          <Row>
            <Button onClick={() => navigate('/login')}>Voltar</Button>
          </Row>
        ) : (
          <Row gap='16px'>
            <Button variant='outlined' onClick={() => navigate('/login')}>
              Voltar
            </Button>
            <Button type='submit' disabled={loading}>
              Enviar
            </Button>
          </Row>
        )}
      </Form>
    </Column>
  )
}

export default ResetPassword
