import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { space, color, typography, layout, compose } from 'styled-system'
import { colorVariants, sizeVariants } from './variants'

import Icon from '../Icon'

const styles = compose(space, color, typography, layout)

const Base = styled.button`
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 4px;
  border-radius: 8px;
  white-space: nowrap;
  border: 1px solid;
  transition: all 0.3s;
  padding: 5px 16px;
  height: fit-content;
  width: fit-content;
  font-weight: 600;

  ${styles};
  ${sizeVariants};
  ${colorVariants};
`

const Button = forwardRef(({ iconLeft, iconRight, children, size, iconProps, ...props }, ref) => (
  <Base ref={ref} size={size} {...props}>
    {iconLeft && <Icon size={size} icon={iconLeft} {...iconProps} />}
    {children}
    {iconRight && <Icon size={size} icon={iconRight} {...iconProps} />}
  </Base>
))

Button.defaultProps = {
  variant: 'filled',
  color: 'primary',
  type: 'button',
  size: 'md',
  iconProps: {},
}

export default Button
