import { useCallback, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import md5 from 'md5'

import Row from 'design-system/Row'
import Column from 'design-system/Column'
import Sidebar from '../Sidebar'
import Header from '../Header'
import NotificationTicketCard from '../NotificationTicketCard'

import { notify } from 'helpers'
import { useAuth, useStorage } from 'hooks'
import { socket } from 'providers'

import { audioNotificationDict, system } from 'configs'

const Layout = () => {
  const [open, setOpen] = useState(false)

  const { userData: user } = useAuth()
  const connectedRoomsRef = useRef([])
  const audioRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()

  const { getStorage } = useStorage()

  const { SOCKET_ENVIRONMENT } = system

  const roomcode = `${SOCKET_ENVIRONMENT}_chatgds_general_${md5(
    `${user?.company_user?.company?.id}_${user?.user.id}`,
  )}`

  const handlerEventMessage = useCallback(
    ({ data, event }) => {
      const { company_user } = user
      const { last_message, attendant, department } = data

      const shouldShowNotification =
        last_message.sender_type === 'contact' &&
        (!attendant || attendant.id === company_user.id || !department)

      if ((event === 'created' || event === 'update') && shouldShowNotification) {
        notify.message(<NotificationTicketCard {...data} />, {
          toastId: data.id,
          closeOnClick: true,
          onClick: () => navigate(`/tickets/${data.id}`, { state: { status: data.status } }),
          progressStyle: {
            background: data?.department?.color,
          },
        })

        audioRef?.current?.play && audioRef.current.play()
      }
    },
    [navigate, user],
  )

  const handleStorageChange = (event) => {
    if (event.key === `chatgds-storage_${SOCKET_ENVIRONMENT}_current_company`) {
      const currentCompany = getStorage('current_company')
      if (currentCompany) {
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!connectedRoomsRef.current.includes(roomcode)) {
      socket.emit('join-room', roomcode)
      connectedRoomsRef.current.push(roomcode)
    }

    audioRef.current = new Audio(audioNotificationDict['facebook'])

    const verifyPathNotification = (data) => {
      const currentPath = window.location.pathname
      if (!currentPath.includes('/tickets')) {
        handlerEventMessage(data)
      }
    }

    socket.on('ticket', verifyPathNotification)

    return () => {
      socket.off('ticket', verifyPathNotification)
    }
  }, [handlerEventMessage, roomcode, location.pathname])

  return (
    <Row>
      <Sidebar setOpen={setOpen} open={open} />
      <Header open={open} />
      <Column
        width='100%'
        p={open ? '60px 0 0 215px' : '60px 0 0 80px'}
        height='100vh'
        className='transition'
      >
        <Outlet />
      </Column>
    </Row>
  )
}

export default Layout
