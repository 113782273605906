import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useAnalytics = () => {
  const location = useLocation()

  useEffect(() => {
    const trackPageView = () => {
      if (window.gtag) {
        window.gtag('config', 'G-H5GBK7XKRJ', {
          page_path: `${location.pathname}${location.search}`,
        })
      }
    }

    trackPageView()
  }, [location])

  const trackEvent = (action, category, label, value) => {
    if (window.gtag) {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
      })
    }
  }

  return { trackEvent }
}

export default useAnalytics
