import { Column, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from 'design-system'

import Historic from './Historic'
import User from './User'
import UserRatingDetails from './UserRatingDetails'

const ModalRatingSurvey = ({ open, onClose, user }) => (
  <Modal open={open} size='lg' closeClickOut onClose={onClose}>
    <ModalHeader onClose={onClose}>
      <Column>
        <Typography variant='title' color='primary'>
          {user?.company_user ? 'Dados do atendente' : 'Conversas sem atendente'}
        </Typography>
      </Column>
    </ModalHeader>
    <ModalBody>
      <Column gap='16px'>
        {user?.company_user && <User user={user} />}
        <UserRatingDetails user={user} hasRating={user?.rated_amount > 0} />
        <Historic user={user} hasRating={user?.rated_amount === 0} />
      </Column>
    </ModalBody>
    <ModalFooter></ModalFooter>
  </Modal>
)

export default ModalRatingSurvey
