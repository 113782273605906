import { useCallback, useEffect, useId, useState } from 'react'
import styled from 'styled-components'
import { Row, Button, Column, Form, TextArea, Icon, FileIconPreview } from 'design-system'
import MessageOptions from './MessageOptions'
import SendButton from './SendButton'
import { useAuth, useStorage } from 'hooks'
import { useForm } from 'react-hook-form'
import { isDateInPast, notify } from 'helpers'
import { sendMessageTicket } from 'services'
import BlockedMessage from './BlockedMessage'
import { colors, fileExtensionsDict } from 'configs'
import ErrorRow from './ErrorRow'
import SignedRow from './SignedRow'
import { ModalExpandImage } from 'components'
import { actionRepliedMessage } from 'providers/TicketContext/store'
import MessageReply from '../TicketMessage/MessageReply'
import AudioMessage from './AudioMessage'

const TicketMessageInput = ({ state, dispatch, setAcceptTicket }) => {
  const { selected_ticket, replied_message } = state

  const { setStorage, getStorage } = useStorage()
  const storageSigned = getStorage('__set_signed')

  const {
    userData: { user },
  } = useAuth()

  const [attachments, setAttachments] = useState([])
  const [loading, setLoading] = useState(false)
  const [expandPreview, setExpandPreview] = useState(null)
  const [signed, setSigned] = useState(storageSigned)
  const [voiceMessage, setVoiceMessage] = useState(false)

  const fileInputId = useId()

  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    setValue,
    getValues,
    formState: { errors },
    setError,
    watch,
  } = useForm()

  const bodyContent = watch('body')

  const isBlockedInteraction = isDateInPast(selected_ticket.conversation_expiration_datetime)
  const isSendDisabled = isBlockedInteraction || (!bodyContent?.trim() && attachments?.length === 0)

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
    const newAttachments = files.map((file) => ({
      file,
      preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
      name: file.name,
      type: fileExtensionsDict[file.type]?.type || 'file',
      extension: file?.type,
      icon: fileExtensionsDict[file.type]?.icon,
    }))
    setAttachments((prevState) => [...prevState, ...newAttachments])
  }

  const removeAttachment = (indexToRemove) => {
    setAttachments((prevAttachments) => {
      const attachmentToRemove = prevAttachments[indexToRemove]
      if (attachmentToRemove.preview) {
        URL.revokeObjectURL(attachmentToRemove.preview)
      }
      return prevAttachments.filter((_, index) => index !== indexToRemove)
    })
  }

  const handlerSigned = (value) => {
    setStorage('__set_signed', value)
    setSigned(value)
  }

  const onSubmit = useCallback(
    async (values, voiceRecord) => {
      if (!voiceRecord && !values?.body?.trim() && attachments.length === 0) {
        return setError('body', {
          message: 'Escreva uma mensagem ou adicione um anexo antes de enviar.',
        })
      }

      try {
        setLoading(true)
        const formData = new FormData()

        const formattedBody = signed ? `*${user?.name}:*\n${values.body}` : values.body

        if (voiceMessage) {
          formData.append(`files[0]file`, voiceMessage)
          formData.append(`files[0]file_type`, 'audio')
          formData.append('message_type', 'file')
          setVoiceMessage(null)
        } else {
          formData.append('body', formattedBody)
        }

        if (attachments.length > 0) {
          attachments.forEach((attachment, idx) => {
            formData.append(`files[${idx}]file`, attachment.file, attachment.name)
            formData.append(`files[${idx}]file_type`, attachment?.type)
          })
          formData.append('message_type', 'file')
        }

        replied_message && formData.append('replied_message', replied_message.id)
        await sendMessageTicket(selected_ticket.id, formData)

        reset({ body: '' })

        attachments?.length > 0 &&
          attachments.forEach((file) => file.preview && URL.revokeObjectURL(file.preview))

        setAttachments(() => [])
        dispatch({ type: actionRepliedMessage.REMOVE_REPLY })
      } catch (err) {
        if (err?.response?.data?.detail) {
          notify.warning('Você deve enviar um template para iniciar a conversa.')
        } else {
          notify.error('Não foi possível enviar mensagem no momento.')
        }
      } finally {
        setLoading(false)
      }
    },
    [
      attachments,
      reset,
      setError,
      signed,
      selected_ticket?.id,
      user,
      replied_message,
      dispatch,
      voiceMessage,
    ],
  )

  useEffect(() => {
    setFocus('body')
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'Enter' && !loading) {
        handleSubmit(onSubmit)()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selected_ticket, attachments, handleSubmit, onSubmit, loading, setFocus])

  return (
    <Column p='10px' width='100%' alignItems='center'>
      {selected_ticket?.status === 'closed' || selected_ticket?.status === 'pending' ? (
        <Button
          maxWidth='320px'
          mb='150px'
          iconLeft='Enter'
          onClick={() => setAcceptTicket(selected_ticket)}
        >
          Atribuir e abrir a conversa
        </Button>
      ) : (
        <>
          {isBlockedInteraction && <BlockedMessage ticket={selected_ticket} />}
          <Form onSubmit={handleSubmit(onSubmit)} borderRadius='4px'>
            {signed && (
              <SignedRow
                signed={signed}
                attendantName={user?.name}
                isBlockedInteraction={isBlockedInteraction}
              />
            )}

            <MessageContainer>
              {replied_message && (
                <MessageReply
                  repliedMessage={replied_message}
                  clear={() => dispatch({ type: actionRepliedMessage.REMOVE_REPLY })}
                  senderType='attendant'
                  name={selected_ticket?.contact?.name}
                  responding
                />
              )}
              <TextArea
                placeholder={isBlockedInteraction || voiceMessage ? '' : 'Digite aqui seu texto'}
                resize='none'
                width='100%'
                disabled={isBlockedInteraction || loading || voiceMessage}
                containerProps={{
                  border: 'none',
                  borderRadius: '4px 4px 0 0',
                }}
                {...register('body')}
              />
              {errors?.body?.message && <ErrorRow errors={errors} />}
              <FileInput id={fileInputId} type='file' onChange={handleFileChange} multiple />
            </MessageContainer>
            <Row
              background='white'
              borderLeft={`1px solid ${colors.grey[50]}`}
              borderRight={`1px solid ${colors.grey[50]}`}
              gap='8px'
              p='10px 8px'
              flexWrap='wrap'
            >
              {attachments.map((file, index) => (
                <ImageWrapper key={index}>
                  <FileIconPreview
                    width='55px'
                    height='55px'
                    borderRadius='8px'
                    type={file?.extension}
                    onClick={() => (file.type === 'image' ? setExpandPreview(file) : null)}
                    {...(file?.preview && { src: file?.preview })}
                  />
                  <Column
                    position='absolute'
                    backgroundColor={colors.grey[50]}
                    borderRadius='100%'
                    top='-5px'
                    right='-5px'
                    className='cursor-pointer'
                  >
                    <Icon
                      icon='Close'
                      size='xs'
                      color='red'
                      onClick={() => removeAttachment(index)}
                    />
                  </Column>
                </ImageWrapper>
              ))}
            </Row>
            <Row
              background='#fff'
              alignItems='center'
              justifyContent='space-between'
              border='1px solid #E8E8E8'
              borderRadius='0 0 8px 8px'
              p='5px 10px'
            >
              {voiceMessage ? (
                <AudioMessage
                  setVoiceMessage={setVoiceMessage}
                  onSubmit={handleSubmit((data) => onSubmit(data, voiceMessage))}
                  loading={loading}
                />
              ) : (
                <MessageOptions
                  signed={signed}
                  handlerSigned={handlerSigned}
                  setValue={setValue}
                  getValues={getValues}
                  ticket={selected_ticket}
                  fileInputId={fileInputId}
                  isBlockedInteraction={isBlockedInteraction}
                  attachments={attachments}
                  setVoiceMessage={setVoiceMessage}
                />
              )}

              {!voiceMessage && (
                <SendButton
                  loading={loading}
                  onSubmit={handleSubmit(onSubmit)}
                  disabled={isSendDisabled}
                  color={isBlockedInteraction ? 'grey.50' : 'grey.300'}
                />
              )}
            </Row>
          </Form>
        </>
      )}
      {expandPreview && (
        <ModalExpandImage
          closeClickOut
          open={expandPreview}
          onClose={() => setExpandPreview(null)}
          data={{ file: expandPreview.preview }}
        />
      )}
    </Column>
  )
}

const MessageContainer = styled(Column)`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.colors.grey[50]}`};
  border-bottom: none;
  padding: 10px 8px;
`

const FileInput = styled.input`
  display: none;
`

const ImageWrapper = styled.div`
  position: relative;
  max-width: 55px;
  max-height: 55px;
  border-radius: 8px;
  &:hover img {
    cursor: pointer;
    border: ${({ theme }) => `3px solid ${theme.colors.primary}`};
  }
`

export default TicketMessageInput
