import Column from 'design-system/Column'
import Typography from '../Typography'
import { colors } from 'configs'

const HighlightedText = ({ hightlight, text }) => (
  <Column p='8px 0' borderBottom={`1px solid ${colors.grey[50]}`}>
    <Typography fontWeight='600'>
      {`${hightlight}: `}
      <Typography as='span'>{text}</Typography>
    </Typography>
  </Column>
)

HighlightedText.defaultProps = {
  hightlight: 'Strong',
  text: 'text',
}

export default HighlightedText
