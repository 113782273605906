import Icon from 'design-system/Icon'
import Tooltip from 'design-system/Tooltip'
import Typography from 'design-system/Typography'
import styled from 'styled-components'
import { space, color, typography, border, layout, compose, background } from 'styled-system'

const style = compose(space, color, border, layout, typography, background)

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 1px 8px;
  font-size: 12px;
  border-radius: 8px;
  height: fit-content;
  white-space: nowrap;
  ${style};
`

const Badge = ({ children, title, fontStyle, action, icon, iconProps, noTooltip, ...props }) =>
  children ? (
    <Tooltip title={noTooltip ? '' : title}>
      <Base {...props}>{children}</Base>
    </Tooltip>
  ) : (
    <Tooltip title={noTooltip ? '' : title}>
      <Base {...props}>
        {icon && <Icon size='xs' icon={icon} {...iconProps} />}
        <Typography as='span' ellipsis {...fontStyle} notSelectable>
          {title}
        </Typography>
        {action && <Icon size='11' icon='Close' color='grey.300' onClick={action} />}
      </Base>
    </Tooltip>
  )

export default Badge
