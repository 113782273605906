import { memo, useState } from 'react'

import { Column } from 'design-system'
import messagesTypeDict from './messagesTypeDict'
import { ModalExpandImage } from 'components'
import MessageFooter from './MessageFooter'
import { colors } from 'configs'
import { hexToRGBA, isDateInPast, notify } from 'helpers'
import styled from 'styled-components'
import MessageReply from './MessageReply'
import PopoverReplyMessage from 'components/popovers/PopoverReplyMessage'
import { CustomContactActionButton } from './Contact'

const fileStyle = {
  pdf: { padding: '0' },
  audio: { minWidth: '300px' },
  image: { width: 'fit-content' },
  video: { width: '280px' },
  other: {},
}

const messageTypeStyles = {
  text: () => ({ width: 'fit-content', maxWidth: '424px', minWidth: '112px' }),
  list: () => ({ width: 'fit-content', maxWidth: '424px', minWidth: '112px' }),
  file: (files) => ({
    ...fileStyle[files[0]?.file_category?.value],
  }),
  template: () => ({ width: 'fit-content', maxWidth: '424px', minWidth: '112px' }),
  location: () => ({ width: '254px', height: '200px', minWidth: '112px' }),
  contact: () => ({ width: '220px' }),
}

const messageFromDict = {
  attendant: ({ message_type, files }) => ({
    padding: '8px 8px 4px 8px',
    borderRadius: '8px 8px 0 8px',
    background: hexToRGBA(colors.violet[100], 0.6),
    ...(messageTypeStyles[message_type](files) || {}),
  }),
  contact: ({ message_type, files }) => ({
    padding: '8px 8px 4px 8px',
    borderRadius: '8px 8px 8px 0',
    background: colors.grey[50],
    ...(messageTypeStyles[message_type](files) || {}),
  }),
  system: ({ message_type, files }) => ({
    padding: '8px 8px 4px 8px',
    borderRadius: '8px',
    maxWidth: '250px',
    background: colors.blue[60],
    ...(messageTypeStyles[message_type](files) || {}),
  }),
  internal: () => ({
    maxWidth: '250px',
    padding: '8px 8px 4px 8px',
    borderRadius: '8px',
    background: colors.blue[60],
  }),
}

const containerDict = {
  attendant: {
    alignItems: 'flex-end',
  },
  contact: {
    alignItems: 'flex-start',
  },
  system: {
    alignItems: 'flex-end',
  },
  internal: {
    alignItems: 'center',
  },
}

const TicketMessage = ({
  message,
  index,
  dispatch,
  actionRepliedMessage,
  replyMenuState,
  containerMessageRef,
  selectedticket,
  ...props
}) => {
  const [expand, setExpand] = useState(null)

  const isBlockedInteraction =
    selectedticket &&
    (isDateInPast(selectedticket.conversation_expiration_datetime) ||
      selectedticket.status === 'pending' ||
      selectedticket.status === 'closed')

  const {
    id,
    message_type,
    sender_type,
    sent_through_whatsapp,
    created_at,
    status,
    files,
    user_sender,
    replied_message,
    body,
  } = message || {}

  const [replyMenu, setReplyMenu] = replyMenuState || []

  const messageFrom = sent_through_whatsapp ? 'attendant' : sender_type

  const messageInput = messagesTypeDict[message_type] || messagesTypeDict['text']

  const handleContextMenu = (e) => {
    e.preventDefault()

    if (replyMenu) return setReplyMenu(null)

    setReplyMenu(() => message)
  }

  const handleReplyMessage = () => {
    dispatch({
      type: actionRepliedMessage.SET_REPLY,
      payload: message,
    })
    setReplyMenu(false)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(body.text)
    setReplyMenu(false)
    notify.success('Mensagem copia com sucesso!')
  }

  return (
    <Column key={`${index}_${id}`} width='100%' {...containerDict[messageFrom]} {...props}>
      <CustomStyles
        position='relative'
        {...messageFromDict[messageFrom]({ message_type, files })}
        {...(sender_type === 'contact' ? { onContextMenu: handleContextMenu } : '')}
      >
        {replied_message && (
          <MessageReply repliedMessage={replied_message} senderType={sender_type} />
        )}
        {messageInput({ message, setExpand })}
        <MessageFooter
          createdAt={created_at}
          userSender={user_sender}
          status={status}
          senderType={sender_type}
        />
        {message_type === 'contact' && (
          <CustomContactActionButton
            contacts={message?.contacts}
            containerMessageRef={containerMessageRef}
          />
        )}
        {replyMenu?.id === id && (!isBlockedInteraction || body?.text) && (
          <PopoverReplyMessage
            open={replyMenu}
            right='0px'
            top='-40px'
            {...(!isBlockedInteraction && { replyClick: handleReplyMessage })}
            {...(body?.text && { copyClick: handleCopy })}
          />
        )}
      </CustomStyles>
      {expand && (
        <ModalExpandImage
          closeClickOut
          open={expand}
          onClose={() => setExpand(null)}
          data={expand}
        />
      )}
    </Column>
  )
}

const CustomStyles = styled(Column)`
  & .conversation__user_sender:first-child {
    visibility: hidden;
  }

  &:hover .conversation__user_sender:first-child {
    visibility: visible;
  }
`

export default memo(TicketMessage)
