import { colors } from 'configs'
import { Card, Column, Icon, Row, Typography } from 'design-system'
import lastMessageDict from '../TicketCard/lastMessageDict'

const MessageReply = ({ repliedMessage, name, senderType, clear, responding }) => {
  const colorReplyDict = {
    attendant: colors.purple[100],
    contact: colors.grey[300],
  }

  return (
    <Card
      className='cursor-pointer'
      mb='8px'
      p='4px 16px'
      borderLeft={`4px solid ${colorReplyDict[senderType]}`}
      borderTop={`1px solid ${colorReplyDict[senderType]}`}
      width='100%'
    >
      <Row justifyContent='space-between' alignItems='center'>
        <Column>
          <Typography fontWeight='600'>{name}</Typography>

          {lastMessageDict[repliedMessage?.message_type || 'text']({
            last_message: repliedMessage,
          })}
        </Column>
        {responding && (
          <Row backgroundColor='grey.50' onClick={clear} borderRadius='100%'>
            <Icon color='grey.300' icon='Close' />
          </Row>
        )}
      </Row>
    </Card>
  )
}

export default MessageReply
