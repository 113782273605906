import { useEffect, useState } from 'react'

import {
  ModalConfirmation,
  ModalImportTemplates,
  PageHeader,
  ProtectedComponent,
  RobotHelp,
} from 'components'
import {
  Column,
  Button,
  Icon,
  Row,
  Card,
  Table,
  ColorChip,
  Typography,
  Tooltip,
  Select,
} from 'design-system'

import { useTemplatesReducer, actionTypes } from './store'
import { notify, withAuthorization } from 'helpers'
import {
  applyReactionsFlow,
  deleteSoftTemplate,
  refreshTemplate,
  showReactionsFlow,
  showTemplatesList,
} from 'services'
import { templateStatusDict } from 'configs'

const Templates = () => {
  const [loading, setLoading] = useState(false)
  const [loadingFlows, setLoadingFlows] = useState(false)
  const [reactionsFlowsList, setReactionFlowsList] = useState([])
  const [modalImport, setModalImport] = useState(null)
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previus: null,
    page_size: 50,
    total_pages: 1,
  })

  const [state, dispatch] = useTemplatesReducer()

  useEffect(() => {
    fetchReactionsFlows()
  }, [])

  useEffect(() => {
    fetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.current_page])

  const fetch = async () => {
    try {
      setLoading(true)
      const { data } = await showTemplatesList(pagination)
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data.results,
      })

      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar lista de templates.')
    } finally {
      setLoading(false)
    }
  }

  const fetchReactionsFlows = async () => {
    try {
      setLoadingFlows(true)

      const { data } = await showReactionsFlow()

      setReactionFlowsList(data.results)
    } catch {
      notify.error('Não foi possível listar reações.')
    } finally {
      setLoadingFlows(false)
    }
  }

  const handleRefresh = async (id) => {
    try {
      const { data } = await refreshTemplate(id)

      dispatch({
        type: actionTypes.UPDATE_ITEM,
        payload: data,
      })
      notify.success('Template atualizado com sucesso.')
      setModalConfirmation(null)
    } catch {
      notify.error('Não foi possível atualizado a template.')
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteSoftTemplate(id)
      dispatch({
        type: actionTypes.REMOVE_ITEM,
        payload: id,
      })
      notify.success('Template excluído com sucesso.')
      setModalConfirmation(null)
    } catch {
      notify.error('Não foi possível excluir a template.')
    }
  }

  const handleMergeReactionFlow = async (flow, template) => {
    try {
      setLoadingFlows(true)

      await applyReactionsFlow(flow, {
        message_template: template,
        mode: 'merge',
      })

      notify.success('Reação vinculada com sucesso.')
    } catch {
      notify.error('Não foi possível vincular reação ao template.')
    } finally {
      setLoadingFlows(false)
    }
  }

  const columns = [
    {
      header: 'Status',
      render: ({ status }) => (
        <Row alignItems='center' gap='8px'>
          <ColorChip
            color={templateStatusDict[status]?.color || '#f6f6f6'}
            borderRadius='100%'
            width='24px'
            height='24px'
          />
          <Typography>{templateStatusDict[status]?.label || 'Status não identificado'}</Typography>
        </Row>
      ),
    },
    {
      header: 'Título',
      field: 'name',
    },
    {
      header: 'Conexão',
      render: ({ connection }) => <Typography>{connection?.name}</Typography>,
    },
    {
      header: 'Categoria',
      field: 'category',
    },
    {
      header: 'Reações',
      render: ({ id }) => (
        <Row alignItems='center'>
          <Select
            options={reactionsFlowsList}
            keys={{ label: 'name', value: 'id' }}
            onChange={(flow) => handleMergeReactionFlow(flow.id, id)}
            isLoading={loadingFlows}
            disabled={loadingFlows}
          />
        </Row>
      ),
    },
    {
      header: 'Ações',
      render: (row) => (
        <Row gap='20px'>
          <Tooltip title='Atualizar o template'>
            <Icon
              cursor='pointer'
              icon='Refresh'
              color='primary'
              onClick={() => handleRefresh(row.id)}
            />
          </Tooltip>
          <ProtectedComponent allowedRoles={['manage_templates']} unauthorizedComponent={false}>
            <Icon
              cursor='pointer'
              icon='Trash'
              color='danger'
              onClick={() =>
                setModalConfirmation({
                  type: 'danger',
                  title: `Excluir template ${row?.name}.`,
                  message:
                    'Você realmente deseja excluir o template? Se sim, saiba que ele passará pela validação de conteúdo da Meta ao ser recriado.',
                  handler: () => handleDelete(row.id),
                })
              }
            />
          </ProtectedComponent>
        </Row>
      ),
    },
  ]

  return (
    <Column p='24px'>
      <PageHeader title='Templates'>
        <ProtectedComponent allowedRoles={['manage_templates']} unauthorizedComponent={false}>
          <Row gap='8px' justifyContent='flex-end'>
            <Button variant='icon' color='primary' onClick={() => setModalImport(true)}>
              <Icon icon='Download2' />
            </Button>
          </Row>
        </ProtectedComponent>
      </PageHeader>
      <Card mt='16px' overflow='auto'>
        <Table
          title='template'
          columns={columns}
          loading={loading}
          data={state.templates}
          pagination={pagination}
          setPagination={setPagination}
          emptyMessage='Você ainda não criou um template. Você precisa ter um template para iniciar suas conversas com contatos. Você pode importar utilizando o código único.'
          handleEmptyData={() => setModalImport(true)}
        />
      </Card>
      <RobotHelp
        maxWidth='580px'
        mt='16px'
        text='Os templates são mensagens predefinidas que as empresas podem enviar para seus clientes. Eles são usados para enviar informações estruturadas, como lembretes de compromissos, confirmações de pedidos, atualizações de entrega, entre outros, de forma eficiente e padronizada.'
      />
      <ModalImportTemplates
        open={modalImport}
        onClose={() => setModalImport(null)}
        dispatch={dispatch}
        actionTypes={actionTypes}
      />
      <ModalConfirmation
        open={modalConfirmation}
        onClose={() => setModalConfirmation(null)}
        {...modalConfirmation}
      />
    </Column>
  )
}

export default withAuthorization(Templates, ['access_templates'])
