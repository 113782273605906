import { Icon, ListItem, Popover, Typography } from 'design-system'

const PopoverReplyMessage = ({ open, elementRef, replyClick, copyClick, ...props }) => {
  return (
    <Popover ref={elementRef} open={open} {...props}>
      {copyClick && (
        <ListItem onClick={copyClick}>
          <Icon icon='Copy' color='grey.300' />
          <Typography color='grey.300'>Copiar</Typography>
        </ListItem>
      )}
      {replyClick && (
        <ListItem onClick={replyClick}>
          <Icon icon='Reply' color='grey.300' />
          <Typography color='grey.300'>Responder</Typography>
        </ListItem>
      )}
    </Popover>
  )
}

export default PopoverReplyMessage
