import { useEffect, useState } from 'react'

import { Row, Button, Icon, Card, Table, Typography, CopyBoard } from 'design-system'
import { PageHeader, ModalConfirmation, ModalConnections, ProtectedComponent } from 'components'

import { showConnectionsList, showDepartmentsListSelect } from 'services'

import { useSettingsReducer, actionTypes } from './store'
import { notify, withAuthorization } from 'helpers'
import { satisfactionSurveyModeDict } from 'configs'

const Connections = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [departmentsList, setDepartmentsList] = useState([])
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previus: null,
    page_size: 50,
    total_pages: 1,
  })
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [state, dispatch] = useSettingsReducer()

  useEffect(() => {
    fetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.current_page])

  useEffect(() => {
    handleFetchDepartmentsList()
  }, [])

  const fetch = async (searchParam) => {
    try {
      setLoading(true)
      const { data } = await showConnectionsList(pagination, searchParam)
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data.results,
        tab: 'connections',
      })

      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar lista de conexões.')
    } finally {
      setLoading(false)
    }
  }

  const handleFetchDepartmentsList = async () => {
    try {
      const { data } = await showDepartmentsListSelect({ active: true })

      setDepartmentsList(data.results)
    } catch {
      notify.error('Não foi possível carregar listagem de departamentos.')
    }
  }

  const columns = [
    {
      header: 'Identificação única',
      render: ({ uuid }) => <CopyBoard title='Copiar identificador' content={uuid} />,
    },
    {
      header: 'Nome',
      field: 'name',
    },
    {
      header: 'Telefone',
      render: ({ phone }) => <Typography>{phone}</Typography>,
    },
    {
      header: 'Pesquisa de satisfação',
      render: ({ satisfaction_survey_mode }) => (
        <Typography>{satisfactionSurveyModeDict[satisfaction_survey_mode]}</Typography>
      ),
    },
    {
      header: 'Ações',
      render: (row) => (
        <ProtectedComponent allowedRoles={['manage_connections']} unauthorizedComponent={false}>
          <Row gap='20px'>
            <Icon cursor='pointer' icon='Edit' color='primary' onClick={() => setModal(row)} />
          </Row>
        </ProtectedComponent>
      ),
    },
  ]

  return (
    <Card>
      <PageHeader title='Conexões'>
        <Row gap='8px' justifyContent='flex-end'>
          <Button variant='icon' color='primary' onClick={() => setModal(true)}>
            <Icon icon='Add' />
          </Button>
        </Row>
      </PageHeader>
      <Card mt='16px' overflow='auto'>
        <Table
          title='conexões'
          columns={columns}
          loading={loading}
          data={state.connections.sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))}
          pagination={pagination}
          setPagination={setPagination}
          emptyMessage='Você ainda não possui conexões. Adicione uma para começar a receber mensagens de seus contatos.'
          handleEmptyData={() => setModal(true)}
        />
      </Card>
      <ModalConnections
        connection={modal}
        onClose={() => setModal(null)}
        dispatch={dispatch}
        actionTypes={actionTypes}
        departmentsList={departmentsList}
      />

      <ModalConfirmation
        open={modalConfirmation}
        onClose={() => setModalConfirmation(null)}
        {...modalConfirmation}
      />
    </Card>
  )
}

export default withAuthorization(Connections, ['access_connections'])
