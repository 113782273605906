import { Column, HighlightedText } from 'design-system'

const ContactDetails = ({ ticket }) => {
  const { contact } = ticket

  const textDict = {
    boolean: (value) => (value ? 'Marcado' : 'Não marcado'),
    text: (value) => value,
    integer: (value) => value,
    decimal: (value) => value,
  }

  return (
    <Column gap='8px'>
      <HighlightedText hightlight='E-mail' text={contact?.email} />
      {contact?.extra_fields?.map(({ value, extra_field: { field_type, name } }) => (
        <HighlightedText hightlight={name} text={textDict[field_type](value)} />
      ))}
    </Column>
  )
}

export default ContactDetails
