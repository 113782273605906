import { Column, Typography } from 'design-system'

const ResetPasswordInfos = () => {
  return (
    <Column mt='8px'>
      <Typography variant='caption' color='danger'>
        1. A senha não pode ser muito semelhante ao email, nome ou telefone do usuário.
      </Typography>
      <Typography variant='caption' color='danger'>
        2. A senha não deve conter espaços.
      </Typography>
      <Typography variant='caption' color='danger'>
        3. A senha deve ter no mínimo 8 caracteres.
      </Typography>
      <Typography variant='caption' color='danger'>
        4. A senha não pode ser muito comum (ex.: "test123").
      </Typography>
      <Typography variant='caption' color='danger'>
        5. A senha não pode ser composta inteiramente por números.
      </Typography>
    </Column>
  )
}

export default ResetPasswordInfos
