import { colors } from 'configs'
import { Column, Skeleton, Typography } from 'design-system'

const CardAverage = ({ title, value, loading }) => {
  return (
    <Column borderWidthRight='1px' borderRight={`1px solid ${colors.grey[50]}`} pr='20px'>
      <Typography>{title}</Typography>
      {loading ? (
        <Skeleton width='100px' height='35px' />
      ) : (
        <Typography fontSize='24px' fontWeight='600'>
          {value}
        </Typography>
      )}
    </Column>
  )
}

CardAverage.defaultProps = {
  title: 'Título',
  value: '100%',
}

export default CardAverage
