import { memo } from 'react'

const Stop = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path d='M 4 6.667 C 4 5.196 5.196 4 6.667 4 L 17.333 4 C 18.804 4 20 5.196 20 6.667 L 20 17.333 C 20 18.804 18.804 20 17.333 20 L 6.667 20 C 5.196 20 4 18.804 4 17.333 L 4 6.667 Z'></path>
    </svg>
  )
}
const Memo = memo(Stop)
export default Memo
