import { memo, forwardRef } from 'react'

const AlarmTimer = forwardRef((props, ref) => {
  return (
    <svg ref={ref} width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <mask id='mask0_4003_25123' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_4003_25123)'>
        <path
          d='M11.8 16.2L13.2 14.8L10 11.6V7H8V12.4L11.8 16.2ZM18 20.5V18.3C19.2333 17.7167 20.2083 16.8583 20.925 15.725C21.6417 14.5917 22 13.35 22 12C22 10.65 21.6417 9.40833 20.925 8.275C20.2083 7.14167 19.2333 6.28333 18 5.7V3.5C19.8167 4.13333 21.2708 5.22917 22.3625 6.7875C23.4542 8.34583 24 10.0833 24 12C24 13.9167 23.4542 15.6542 22.3625 17.2125C21.2708 18.7708 19.8167 19.8667 18 20.5ZM9 21C7.75 21 6.57917 20.7625 5.4875 20.2875C4.39583 19.8125 3.44583 19.1708 2.6375 18.3625C1.82917 17.5542 1.1875 16.6042 0.7125 15.5125C0.2375 14.4208 0 13.25 0 12C0 10.75 0.2375 9.57917 0.7125 8.4875C1.1875 7.39583 1.82917 6.44583 2.6375 5.6375C3.44583 4.82917 4.39583 4.1875 5.4875 3.7125C6.57917 3.2375 7.75 3 9 3C10.25 3 11.4208 3.2375 12.5125 3.7125C13.6042 4.1875 14.5542 4.82917 15.3625 5.6375C16.1708 6.44583 16.8125 7.39583 17.2875 8.4875C17.7625 9.57917 18 10.75 18 12C18 13.25 17.7625 14.4208 17.2875 15.5125C16.8125 16.6042 16.1708 17.5542 15.3625 18.3625C14.5542 19.1708 13.6042 19.8125 12.5125 20.2875C11.4208 20.7625 10.25 21 9 21Z'
          fill='#3E3E3E'
        />
      </g>
    </svg>
  )
})
const Memo = memo(AlarmTimer)
export default Memo
