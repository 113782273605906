import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Row,
  Button,
  Typography,
  TextArea,
  ColorPicker,
  Card,
  Switch,
  ModalFooter,
  Column,
  Checkbox,
} from 'design-system'

import { createDepartment, showDepartmentInfo, updatedDepartment } from 'services'
import { defaultColorsDepartments, weekDays } from 'configs'
import { notify } from 'helpers'
import WorkdaysSelector from 'components/common/WorkdaysSelector'

const ModalDepartments = ({ onClose, department, dispatch, actionTypes, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [currentColor, setCurrentColor] = useState('')

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm()

  const randomColor = defaultColorsDepartments[0]

  const watchDepartments = watch('working_schedules')

  const initialValues = {
    name: '',
    color: randomColor,
    active: true,
    working_schedules: [],
    show_deparment_in_selector: true,
  }

  useEffect(() => {
    if (department?.id) {
      handleGetInfo()
    }

    reset(initialValues)

    return () => {
      setCurrentColor(randomColor)
      reset(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department])

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      values.working_schedules ||= []

      const hasErrors = values?.working_schedules?.filter((r) => r.selected && (!r.start || !r.end))

      if (hasErrors?.length > 0) {
        hasErrors.forEach((day) => {
          if (!day.start) {
            setError(`working_schedules.${day.weekday}.start`)
          }
          if (!day.end) {
            setError(`working_schedules.${day.weekday}.end`)
          }
        })
        return false
      }

      values.working_schedules = values?.working_schedules?.filter(
        (f) => f.selected && f.start && f.end,
      )

      const { data } = department?.id
        ? await updatedDepartment(department?.id, values)
        : await createDepartment(values)

      dispatch({
        type: department?.id ? actionTypes.UPDATE_ITEM : actionTypes.ADD_ITEM,
        payload: data,
      })
      notify.success(
        department?.id
          ? 'Departamento salvo com sucesso.'
          : 'Novo departamento criado com sucesso.',
      )
      onClose()
    } catch {
      notify.error('Não foi possível salvar configuração do departamento.')
    } finally {
      setLoading(false)
    }
  }

  const handleGetInfo = async () => {
    try {
      setLoading(true)
      const { data } = await showDepartmentInfo(department?.id)

      setCurrentColor(data.color)

      data.working_schedules = weekDays.map((w) => ({
        ...w,
        ...data.working_schedules.find((ws) => ws.weekday === w.weekday),
        selected: !!data.working_schedules.find((ws) => ws.weekday === w.weekday),
      }))

      reset(data)
    } catch {
      notify.error('Não foi possível buscar dados do departamento.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal size='md' open={department} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          {department?.id ? 'Editar departamento' : 'Criar departamento'}
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row justifyContent='flex-end' gap='8px' alignItems='center'>
            <Typography>Departamento ativo</Typography>
            <Switch {...register('active')} />
          </Row>
          <Card mt='16px'>
            <Row gap='16px'>
              <Input
                label='Nome'
                error={errors?.name}
                disabled={loading}
                required
                errorMessage={
                  errors?.name?.type === 'maxLength' && 'O nome pode ter no máximo 24 caracteres.'
                }
                {...register('name', {
                  required: true,
                  maxLength: 24,
                })}
              />
              <ColorPicker
                maxWidth='100px'
                label='Destaque'
                name='color'
                defaultValue={currentColor}
                onChange={setValue}
                colorsList={defaultColorsDepartments}
              />
            </Row>
            <Row mt='16px'>
              <Column width='100%' gap='8px'>
                <TextArea
                  label='Mensagem de saudação'
                  height='130px'
                  error={errors?.greeting_message}
                  emojis
                  textvariables
                  setValue={setValue}
                  getValues={getValues}
                  {...register('greeting_message')}
                />
                <Checkbox
                  label='Desativar o envio de saudação para transferências manuais de departamentos.'
                  {...register('send_greeting_message_in_manual_transfer')}
                />
              </Column>
            </Row>
            <Row mt='16px'>
              <TextArea
                label='Mensagem de ausência'
                height='130px'
                error={errors?.away_message}
                emojis
                textvariables
                setValue={setValue}
                getValues={getValues}
                {...register('away_message')}
              />
            </Row>
            <Row mt='16px'>
              <TextArea
                label='Mensagem de finalização'
                height='130px'
                error={errors?.farewell_message}
                emojis
                textvariables
                setValue={setValue}
                getValues={getValues}
                {...register('farewell_message')}
              />
            </Row>
            <Row mt='16px'>
              <WorkdaysSelector
                initialValue={watchDepartments}
                setValue={setValue}
                name='working_schedules'
                control={control}
                register={register}
                errors={errors}
                loading={loading}
                reset={reset}
              />
            </Row>
            <Column mt='16px'>
              <Checkbox
                label='Exibir este departamento na listagem'
                description='Marque se deseja que este departamento apareça para os contatos ao selecionar um
                departamento.'
                {...register('show_deparment_in_selector')}
              />
            </Column>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalDepartments
