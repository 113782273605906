import { ModalHistoricTicket } from 'components'
import { colors } from 'configs'
import { format } from 'date-fns'
import { Badge, Column, Input, Row, Typography } from 'design-system'
import { hexToRGBA, notify } from 'helpers'
import { useDebounce, useTickets } from 'hooks'
import { useEffect, useState } from 'react'
import { showTicketList } from 'services'

const TicketHistoric = ({ setAccordionLoading, ticket }) => {
  const [historicList, setHistoricList] = useState([])
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [search, setSearch] = useState('')

  const { state } = useTickets()

  const debounceSearch = useDebounce(search, 1500)

  const { contact } = ticket || {}

  useEffect(() => {
    ticket?.id && handlerGetHistoric(debounceSearch)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket?.id, debounceSearch, state?.selected_ticket?.closed_at])

  const handlerGetHistoric = async (search) => {
    try {
      setAccordionLoading(true)

      const { data } = await showTicketList({
        contact: contact?.id,
        status: 'closed',
        content: search,
        ordering: '-closed_at',
      })

      setHistoricList(data.results)
    } catch {
      notify.error('Não foi possível buscar histórico de conversa.')
    } finally {
      setAccordionLoading(false)
    }
  }

  return (
    <Column>
      {historicList.length > 0 && (
        <Column mb='16px'>
          <Input
            type='search'
            icon='Search'
            iconProps={{ color: 'grey.200' }}
            placeholder='Pesquisar histórico do contato'
            value={search}
            onChange={(event) => setSearch(event.target.value.toLowerCase())}
          />
        </Column>
      )}
      <Column>
        {historicList.length > 0 ? (
          historicList.map(({ closed_at, department, attendant, id, contact }, idx) => (
            <Column
              key={idx}
              className='cursor-pointer'
              p='8px'
              borderBottom={`1px solid ${colors.grey[50]}`}
              onClick={() => setSelectedTicket({ id, contact, attendant, closed_at, department })}
            >
              <Row width='100%' justifyContent='space-between'>
                <Row gap='4px' width='100%'>
                  <Typography>{format(closed_at, 'dd/MM/yyyy HH:mm')}</Typography>
                </Row>
                {department && (
                  <Badge
                    width='100px'
                    backgroundColor={hexToRGBA(department?.color, 0.2)}
                    color={department?.color}
                    title={department?.name}
                    fontStyle={{ fontSize: '12px' }}
                  />
                )}
              </Row>
              <Row>
                <Typography fontSize='12px'>{`Responsável: ${
                  attendant?.name || 'Sem responsável'
                }`}</Typography>
              </Row>
            </Column>
          ))
        ) : (
          <Column m='16px 0' width='100%'>
            <Typography textAlign='center'>Nenhum histórico encontrado.</Typography>
          </Column>
        )}
      </Column>
      {ticket && (
        <ModalHistoricTicket
          open={selectedTicket}
          ticket={selectedTicket}
          historicList={historicList}
          onClose={() => setSelectedTicket(null)}
        />
      )}
    </Column>
  )
}

export default TicketHistoric
