import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  Typography,
  ModalFooter,
  SelectAsync,
  Select,
  Card,
  Icon,
} from 'design-system'

import { showDepartmentsListSelect, showCompanyUsersListSelect, updateTicket } from 'services'
import { notify } from 'helpers'
import { colors } from 'configs'
import { useNavigate } from 'react-router-dom'

const ModalAcceptTicket = ({
  onClose,
  ticket,
  dispatch,
  actionTypes,
  setLoading,
  loading,
  ...props
}) => {
  const [attendants, setAttendants] = useState([])
  const [error, setError] = useState(null)

  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { active: true } })

  const watchDepartment = watch('department')

  useEffect(() => {
    watchDepartment && getUserOptions(watchDepartment)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDepartment])

  useEffect(() => {
    ticket?.department && getUserOptions(ticket?.department?.id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket])

  const onSubmit = async (values) => {
    try {
      values.attendant = values.attendant.id
      values.department = values.department.id

      const { data } = await updateTicket(ticket.id, { ...values, status: 'attending' })

      dispatch({
        type: actionTypes.UPDATE_SELECTED_TICKET,
        payload: data,
      })

      notify.success('Conversa atribuída com sucesso.')
      onClose()
    } catch (e) {
      if (e?.response?.data) {
        setError(e?.response?.data)
        return notify.error(e?.response?.data?.detail[0])
      }

      notify.error('Não foi possível salvar configuração da etiqueta.')
    }
  }

  const getUserOptions = async (department) => {
    try {
      const { data } = await showCompanyUsersListSelect({
        page_size: 100,
        active: true,
        departments: department?.id,
      })

      const findCurrentUser = data.results.find((i) => i.id === ticket?.attendant?.id)

      setValue('attendant', findCurrentUser || null)

      setAttendants(() => data.results)
    } catch {
      notify.error('Não foi possível resgatar lista de departamentos.')
    }
  }

  const getDepartmentsOptions = async (search) =>
    (await showDepartmentsListSelect({ page_size: 100, active: true })).data.results

  return (
    <Modal size='sm' open={ticket} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          Atribuir Responsável
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row width='100%'>
            <SelectAsync
              label='Departamento'
              cacheOptions
              defaultOptions
              loadOptions={getDepartmentsOptions}
              defaultValue={ticket?.department}
              keys={{ label: 'name', value: 'id' }}
              name='department'
              control={control}
              error={errors?.department}
              required
            />
          </Row>
          <Row mt='16px' width='100%'>
            <Select
              label='Atendente responsável'
              defaultOptions
              options={attendants}
              defaultValue={ticket?.attendant}
              keys={{ label: 'name', value: 'id' }}
              name='attendant'
              control={control}
              error={errors?.attendant}
              required
            />
          </Row>
          {error?.detail?.length > 0 && (
            <Card
              mt='16px'
              p='12px'
              backgroundColor='grey.50'
              border={`1px solid ${colors.orange[400]}`}
              borderLeft={`5px solid ${colors.orange[400]}`}
              justifyContent='center'
            >
              <Row gap='8px'>
                <Icon icon='Alert' color='grey.300' />
                <Typography>{error?.detail[0]}</Typography>
              </Row>
              {error?.ticket_id?.length && (
                <Row justifyContent='flex-end'>
                  <Typography
                    textAlign='right'
                    color='blue.80'
                    fontWeight='600'
                    cursor='pointer'
                    onClick={() => {
                      navigate(`/tickets/${error?.ticket_id[0]}`)
                      onClose()
                    }}
                  >
                    Acessar conversa
                  </Typography>
                </Row>
              )}
            </Card>
          )}
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.300' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalAcceptTicket
