import { Row, Skeleton } from 'design-system'

const SkeletonSurveyEmoji = () => {
  return (
    <Row gap='16px'>
      <Skeleton width='95px' height='50px' />
      <Skeleton width='95px' height='50px' />
      <Skeleton width='95px' height='50px' />
      <Skeleton width='95px' height='50px' />
      <Skeleton width='95px' height='50px' />
      <Skeleton width='95px' height='50px' />
    </Row>
  )
}

export default SkeletonSurveyEmoji
