import PopoverSelectCompany from 'components/popovers/PopoverSelectCompany'
import { colors } from 'configs'
import { Icon, Row, Typography } from 'design-system'
import { useClickout } from 'hooks'

const CompanyMenu = (props) => {
  const { triggerRef, elementRef, openedState } = useClickout(false)

  return (
    <Row position='relative'>
      <Row
        alignItems='center'
        justifyContent='space-between'
        border={`1px solid ${colors.grey[50]}`}
        borderRadius='8px'
        p='5px 8px'
        className='cursor-pointer'
        boxShadow={openedState ? '0px 0px 0px 4px rgba(182, 173, 215, 0.2)' : ''}
        ref={triggerRef}
        {...props}
      >
        <Typography color='grey.50'>{props?.company?.name || 'Selecione sua company'}</Typography>
        <Icon color='grey.50' icon='Arrow' direction='bottom' />
      </Row>
      {openedState && (
        <PopoverSelectCompany right='0' top='45px' open={openedState} elementRef={elementRef} />
      )}
    </Row>
  )
}

export default CompanyMenu
