import { forwardRef, useState, Children, cloneElement } from 'react'
import styled from 'styled-components'
import { space, layout, compose } from 'styled-system'

import Icon from '../Icon'
import Typography from '../Typography'
import Spinner from 'design-system/Spinner'

const styles = compose(space, layout)

const AccordionContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey[50]}`};

  overflow: auto;
  ${styles};
`

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.grey[40]};
  &:hover,
  &:hover p {
    cursor: pointer;
  }
`

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`

const LoaderContainer = styled.div`
  display: flex;
`

const AccordionContent = styled.div`
  border-radius: 8px;
  transition: all 0.3s ease;
  overflow: hidden;
  max-height: 400px;
  height: ${({ open }) => (open ? 'auto' : '0')};
  padding: ${({ open }) => (open ? '16px 18px' : '0px 18px')};
  background-color: ${({ theme }) => theme.colors.grey[40]};
`

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 100%;
  transition: all 0.3 ease;
  box-shadow: 0px 1px 8px 0px #0e1e2f08;
  border: ${({ theme }) => `1px solid ${theme.colors.grey[50]}`};
`

const Accordion = forwardRef(({ title, children, defaultOpened, ...props }, ref) => {
  const [open, setOpen] = useState(defaultOpened)
  const [accordionLoading, setAccordionLoading] = useState(false)

  const cloneChildren = Children.map(children, (child) =>
    cloneElement(child, { accordionLoading, setAccordionLoading }),
  )

  return (
    <AccordionContainer ref={ref} {...props}>
      <AccordionHeader
        onClick={() => !accordionLoading && setOpen(!open)}
        aria-expanded={open}
        aria-controls='accordion-content'
        role='button'
      >
        <IconContainer>
          <ActionButton>
            <Icon
              icon='Arrow'
              direction={open ? 'bottom' : 'right'}
              color={!accordionLoading ? 'secondary' : 'grey.50'}
            />
          </ActionButton>
          <Typography
            fontWeight='600'
            color={!accordionLoading ? 'grey.300' : 'grey.50'}
            notSelectable
          >
            {title}
          </Typography>
        </IconContainer>
        {accordionLoading && (
          <LoaderContainer>
            <Spinner size='sm' />
          </LoaderContainer>
        )}
      </AccordionHeader>
      <AccordionContent open={open}>{cloneChildren}</AccordionContent>
    </AccordionContainer>
  )
})
Accordion.defaultProps = {
  title: 'Accordion',
  color: 'primary',
  defaultOpened: false,
}

export default Accordion
